const loadGoogleMaps = (apiKey) => {
  return new Promise((resolve, reject) => {
    // Check if Google Maps script is already included
    if (window.google && window.google.maps) {
      resolve(window.google.maps);
      return;
    }

    // Create the script tag
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=geometry,drawing,places`;
    script.async = true;
    script.defer = true;

    // Set up event handlers
    script.onload = () => {
      resolve(window.google.maps);
    };
    script.onerror = (error) => {
      reject(error);
    };

    // Append the script to the document head
    document.head.appendChild(script);
  });
};

export default loadGoogleMaps;
