/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { useSwiper } from 'swiper/react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import PropTypes from 'prop-types';

function SwiperNavButtonsTypeB({ isBeginning, isEnd }) {
  const swiper = useSwiper();

  return (
    <div className="d-flex flex-row align-items-center swiper-navigation-type-b">
      <button
        className={`swiper-btn-navigation-type-a ${isBeginning ? 'disabled' : ''}`}
        onClick={() => swiper.slidePrev()}
        type="button"
      >
        <FaAngleLeft color={isBeginning ? '#d7d7d7' : '#333333'} />
      </button>
      <button
        className={`ms-2 swiper-btn-navigation-type-a ${isEnd ? 'disabled' : ''}`}
        onClick={() => swiper.slideNext()}
        type="button"
      >
        <FaAngleRight color={isEnd ? '#d7d7d7' : '#333333'} />
      </button>
    </div>
  );
}

SwiperNavButtonsTypeB.propTypes = {
  isEnd: PropTypes.bool.isRequired,
  isBeginning: PropTypes.bool.isRequired,
};

export default SwiperNavButtonsTypeB;
