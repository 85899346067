/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import defaultImage from 'assets/pexels-pixabay-164558 2.png';
import { shortenNumber } from 'utils/common';
import { Link } from 'react-router-dom';

function CardPropertyTypeJ({
  id,
  name,
  location,
  price,
  discountedPrice,
  lotArea,
  floorArea,
  bedroomTotal,
  bathroomTotal,
  phoneNumber,
  cityName,
  coverImageUrl,
}) {
  return (
    <div className="card-property-type-i-container">
      <div className="card-property-type-i-header">
        <Link to={`/properties/${id}`} className="card-property-type-i-image-container">
          <img src={coverImageUrl || defaultImage} alt="property" className="w-100" />
        </Link>
      </div>

      <div className="card-property-type-i-body">
        <Link to={`/properties/${id}`} className="card-property-type-i-body--title">
          {name.length > 25 ? `${name.slice(0, 25)}...` : name}
        </Link>

        <div className="card-property-type-i-body--location">
          <svg
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00001 0.25C5.17732 0.252216 3.42993 0.977256 2.1411 2.26609C0.852264 3.55492 0.127223 5.30232 0.125007 7.125C0.122855 8.61454 0.60947 10.0636 1.51019 11.25C1.51019 11.25 1.69769 11.4966 1.72782 11.5323L7.00001 17.75L12.2744 11.5296C12.3021 11.4963 12.4898 11.2498 12.4898 11.2498L12.4908 11.2483C13.3909 10.0623 13.8772 8.61386 13.875 7.125C13.8728 5.30232 13.1478 3.55492 11.8589 2.26609C10.5701 0.977256 8.82269 0.252216 7.00001 0.25ZM7.00001 9.625C6.50555 9.625 6.0222 9.47838 5.61108 9.20367C5.19996 8.92897 4.87953 8.53852 4.69031 8.08171C4.50109 7.62489 4.45158 7.12223 4.54804 6.63727C4.64451 6.15232 4.88261 5.70686 5.23224 5.35723C5.58187 5.0076 6.02733 4.7695 6.51228 4.67304C6.99723 4.57657 7.4999 4.62608 7.95672 4.8153C8.41353 5.00452 8.80398 5.32495 9.07868 5.73607C9.35338 6.1472 9.50001 6.63055 9.50001 7.125C9.49926 7.78781 9.23563 8.42326 8.76695 8.89194C8.29827 9.36062 7.66282 9.62426 7.00001 9.625Z"
              fill="#333333"
            />
          </svg>

          <span className="ms-2 app-color-text-1 card-property-type-i-body--location-text">
            {location + ', ' + cityName}
          </span>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <div>
            {/* <div className="card-property-type-i-body--price-text-i">Rp {price || 0.5} Miliar</div> */}
            {/* <div className="card-property-type-i-body--price-text-ii">
              Rp {discountedPrice || 0.3} Miliar
            </div> */}
            <div className="card-property-type-i-body--price-text-i">{`Rp ${shortenNumber(price)}`}</div>
            <div className="card-property-type-i-body--price-text-ii">
              {`Rp ${shortenNumber(discountedPrice)}`}
            </div>
          </div>
          <a
            href={`https://wa.me/${phoneNumber || '1234567890'}`}
            className="card-property-type-i-body--btn-phone"
          >
            <span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3539 10.875H10.2902C2.92144 10.4513 1.87519 4.23375 1.72894 2.33625C1.71716 2.18872 1.7346 2.04031 1.78027 1.89953C1.82594 1.75875 1.89894 1.62836 1.99509 1.51584C2.09123 1.40333 2.20864 1.31088 2.34057 1.24381C2.47251 1.17675 2.61638 1.13637 2.76394 1.125H4.83019C4.9804 1.12485 5.1272 1.16982 5.25156 1.25406C5.37592 1.33831 5.47212 1.45795 5.52769 1.5975L6.09769 3C6.15258 3.13633 6.1662 3.28578 6.13686 3.42978C6.10753 3.57379 6.03653 3.706 5.93269 3.81L5.13394 4.61625C5.25871 5.32528 5.59826 5.97888 6.10666 6.48861C6.61505 6.99834 7.26775 7.33961 7.97644 7.46625L8.79019 6.66C8.89576 6.55731 9.02921 6.48797 9.17392 6.46063C9.31863 6.43329 9.46819 6.44915 9.60394 6.50625L11.0177 7.0725C11.1551 7.12983 11.2724 7.22678 11.3545 7.351C11.4367 7.47522 11.48 7.62108 11.4789 7.77V9.75C11.4789 10.0484 11.3604 10.3345 11.1494 10.5455C10.9385 10.7565 10.6523 10.875 10.3539 10.875Z"
                  fill="white"
                />
              </svg>
            </span>
            <span className="ms-1">Hubungi</span>
          </a>
        </div>
      </div>

      <div className="card-property-type-i-footer">
        <div className="card-property-type-i-footer--content">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2731 7.10564H13.7271C14.8626 7.10666 15.8486 7.88806 16.1091 8.99339C16.1309 9.08553 16.2131 9.15067 16.3078 9.15089H20.0683C20.1225 9.15109 20.1746 9.12968 20.213 9.0914C20.2515 9.05312 20.2731 9.00112 20.2731 8.94689V6.90089C20.2731 6.46693 20.1006 6.05076 19.7937 5.74397C19.4868 5.43719 19.0705 5.26494 18.6365 5.26514H6.36355C5.92959 5.26494 5.51334 5.43719 5.20642 5.74397C4.89949 6.05076 4.72705 6.46693 4.72705 6.90089V8.94689C4.72705 9.00112 4.74865 9.05312 4.78707 9.0914C4.82549 9.12968 4.87757 9.15109 4.9318 9.15089H8.6923C8.78719 9.15122 8.86969 9.08585 8.89105 8.99339C9.15148 7.88806 10.1375 7.10666 11.2731 7.10564Z"
              fill="#333333"
            />
            <path
              d="M4.31825 10.3785C4.10118 10.3783 3.89294 10.4645 3.73944 10.618C3.58595 10.7715 3.4998 10.9797 3.5 11.1968V15.492C3.5 15.8809 3.77371 16.2161 4.15475 16.2938C4.24983 16.3133 4.31811 16.397 4.31825 16.494V18.1508C4.31825 18.6027 4.68459 18.969 5.1365 18.969C5.58841 18.969 5.95475 18.6027 5.95475 18.1508V16.515C5.95455 16.4608 5.97596 16.4087 6.01423 16.3703C6.05251 16.3319 6.10452 16.3103 6.15875 16.3103H18.8412C18.8955 16.3103 18.9475 16.3319 18.9858 16.3703C19.024 16.4087 19.0454 16.4608 19.0452 16.515V18.1508C19.0452 18.6027 19.4116 18.969 19.8635 18.969C20.3154 18.969 20.6817 18.6027 20.6817 18.1508V16.494C20.6819 16.397 20.7502 16.3133 20.8452 16.2938C21.2263 16.2161 21.5 15.8809 21.5 15.492V11.1968C21.5002 10.9797 21.4141 10.7715 21.2606 10.618C21.1071 10.4645 20.8988 10.3783 20.6817 10.3785H4.31825Z"
              fill="#333333"
            />
          </svg>
          <span className="ms-2">{bedroomTotal}</span>
        </div>
        <div className="card-property-type-i-footer--content">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.2509 13.499C20.4584 13.5005 20.6561 13.5873 20.7977 13.739C20.9397 13.8877 21.0133 14.0888 21.001 14.2941C20.7756 18.0605 17.6556 20.9996 13.8825 21H10.117C6.3439 20.9996 3.22394 18.0605 2.99854 14.2941C2.98865 14.0894 3.06166 13.8893 3.20106 13.739C3.34162 13.5854 3.54045 13.4983 3.74864 13.499H6.46251C6.6279 13.5005 6.77478 13.3936 6.82406 13.2357C7.01995 12.6128 7.59627 12.1881 8.24925 12.1855H12.0035C12.6565 12.1881 13.2328 12.6128 13.4287 13.2357C13.4776 13.3922 13.6225 13.4989 13.7865 13.499H17.8123C18.0194 13.499 18.1874 13.3311 18.1874 13.1239V5.24785C18.1882 4.95142 18.0144 4.68229 17.7438 4.56113C17.4733 4.43997 17.1568 4.48951 16.9362 4.68752L16.6286 4.99507C16.5286 5.09585 16.494 5.24453 16.5394 5.37912C16.8009 6.15164 16.6025 7.00565 16.0271 7.58367L15.877 7.73744C15.5841 8.03027 15.1093 8.03027 14.8164 7.73744L12.8324 5.75267C12.5396 5.45975 12.5396 4.98494 12.8324 4.69202L12.9861 4.53825C13.571 3.97951 14.4155 3.78706 15.1847 4.03719C15.3183 4.07963 15.4644 4.04405 15.5635 3.94492L15.9108 3.59763C16.5671 2.99118 17.52 2.83051 18.3389 3.18823C19.1577 3.54595 19.6873 4.35428 19.6883 5.24785V13.1239C19.6883 13.3311 19.8562 13.499 20.0634 13.499H20.2509ZM11.6252 18.1875C11.8323 18.1875 12.0003 18.0195 12.0003 17.8124V14.0614C12.0003 13.8542 11.8323 13.6863 11.6252 13.6863H8.62439C8.41723 13.6863 8.2493 13.8542 8.2493 14.0614V17.8124C8.2493 18.0195 8.41723 18.1875 8.62439 18.1875H11.6252Z"
              fill="#333333"
            />
            <path
              d="M9.74956 7.56451C9.85787 7.56545 9.96511 7.54293 10.0639 7.49849L11.4262 6.86983C11.67 6.75793 11.8356 6.52449 11.8605 6.25743C11.8855 5.99038 11.7661 5.73029 11.5473 5.57513C11.3285 5.41998 11.0436 5.39333 10.7998 5.50523L9.43523 6.13314C9.11532 6.28075 8.94123 6.6311 9.0168 6.97522C9.09237 7.31935 9.39723 7.56452 9.74956 7.56451Z"
              fill="#333333"
            />
            <path
              d="M13.5915 11.6308C13.9725 11.7933 14.4131 11.6163 14.5757 11.2354L15.1654 9.85504C15.3175 9.47641 15.139 9.04567 14.7638 8.88548C14.3885 8.72529 13.954 8.89441 13.7858 9.26614L13.1999 10.6487C13.0384 11.0281 13.2133 11.4666 13.5915 11.6308Z"
              fill="#333333"
            />
            <path
              d="M10.8641 9.80588C11.1556 10.1002 11.6305 10.1026 11.9249 9.81114L12.9917 8.75636C13.2781 8.46369 13.2769 7.99539 12.989 7.70418C12.7011 7.41298 12.2328 7.40648 11.9369 7.68959L10.8701 8.74511C10.5756 9.0364 10.5729 9.51128 10.8641 9.80588Z"
              fill="#333333"
            />
          </svg>
          <span className="ms-2">{bathroomTotal}</span>
        </div>
        <div className="card-property-type-i-footer--content">
          LT {lotArea} m<sup>2</sup>
        </div>
        <div className="card-property-type-i-footer--content">
          LB {floorArea} m<sup>2</sup>
        </div>
      </div>
    </div>
  );
}

CardPropertyTypeJ.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discountedPrice: PropTypes.number.isRequired,
  lotArea: PropTypes.number.isRequired,
  floorArea: PropTypes.number.isRequired,
  bedroomTotal: PropTypes.number.isRequired,
  bathroomTotal: PropTypes.number.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string,
};

export default CardPropertyTypeJ;
