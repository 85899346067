/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './propertyCooperation.modules.css';

function PropertyCooperation() {
  return (
    <main className="main-container">
      <div className="property-cooperation-section-a--container">
        <div className="property-cooperation-section-a-i-inner-container">
          <h1 className="property-cooperation-section-a--text-a">Kesempatan Kerjasama</h1>
          <h1 className="property-cooperation-section-a--text-a">Properti Menguntungkan!</h1>
          <p className="property-cooperation-section-a--text-b">
            Kami mengundang Anda untuk bergabung dalam kesempatan kerjasama properti yang
            menguntungkan ini. Dalam kerangka kerjasama ini, kami menawarkan kolaborasi yang saling
            menguntungkan untuk mengembangkan properti yang menjanjikan dan menghasilkan keuntungan
            yang berkelanjutan.
          </p>
          <div
            style={{ marginTop: '32px' }}
            className="w-100 d-flex flex-row justify-content-center align-items-center"
          >
            <Link to="/property-cooperation" className="btn-join-now">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0L10.9 1.1L16.9 7.2H0V8.8H16.9L10.9 14.9L12 16L20 8L12 0Z"
                  fill="white"
                />
              </svg>
              <span className="ms-3">Bergabung Sekarang</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PropertyCooperation;
