/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import propertyService from 'services/property.service';
import CardPropertyTypeJ from 'components/Cards/CardPropertyTypeJ';
import { useWindowSize } from '../../hooks/useWindowSize.jsx';
//import CardPropertyTypeK from 'components/Cards/CardPropertyTypeK';
import DropdownTypeA from 'components/Dropdowns/DropdownTypeA';
import arrowRight from 'assets/arrow--right.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './home.modules.css';

function Home() {
  const windowSize = useWindowSize();
  const [payload, setPayload] = useState({
    page: 1,
    limit: 9,
    sort_by: 'id',
    is_asc: true,
    search: '',
    property_type_id: null,
    city_id: null,
    sales_type_id: null,
    price_selection_id: null,
  });

  const [propertyList, setPropertyList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [propertyTypesList, setPropertyTypesList] = useState([]);
  const [salesTypesList, setSalesTypesList] = useState([]);
  const [priceSelectionsList, setPriceSelectionsList] = useState([]);

  const calculateWindowSize = (windowWidth) => {
    if (windowWidth <= 600) {
      setPayload((prevValue) => {
        return { ...prevValue, limit: 5 };
      });
    }
  };

  async function fetchProperty() {
    try {
      const payloadData = { ...payload, limit: windowSize.width <= 600 ? 5 : payload.limit };
      const response = await propertyService.propertyList(payloadData);
      setPropertyList([...response?.data?.content]);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCities() {
    try {
      const response = await propertyService.cities();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });

      setCityList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchPropertyTypes() {
    try {
      const response = await propertyService.propertyTypes();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setPropertyTypesList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSalesTypes() {
    try {
      const response = await propertyService.salesTypes();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setSalesTypesList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchPriceSelections() {
    try {
      const response = await propertyService.priceSelections();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setPriceSelectionsList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  function handleDropdown(param, value) {
    console.log(param, value);
    // console.log({ ...payload, [param]: value });
    setPayload({ ...payload, [param]: value });
    // fetchProperty();
  }

  useEffect(() => {
    fetchProperty();
  }, [payload]);

  useEffect(() => {
    //fetchWaterSources();
    fetchCities();
    fetchPropertyTypes();
    fetchSalesTypes();
    fetchPriceSelections();
  }, []);

  useEffect(() => {
    calculateWindowSize(windowSize.width);
  }, [windowSize]);

  //useEffect(() => console.log(payload), [payload]);

  return (
    <main className="main-container">
      <div className="home-section-a--container">
        <div className="home-section-a-i-inner-container">
          <h1 className="home-section-a--text-a">Best value Indonesia</h1>
          <h1 className="home-section-a--text-a">properties</h1>
          <p className="home-section-a--text-b">
            Selamat datang di peluang investasi properti yang menarik! Kami memiliki penawaran
            eksklusif untuk Anda yang sedang mencari investasi properti yang menguntungkan. Dapatkan
            kesempatan emas untuk memiliki properti yang terletak di lokasi strategis dengan potensi
            pertumbuhan nilai yang tinggi.
          </p>
        </div>
        <div className="home-section-a-ii-inner-container">
          <div className="home-section-a--object">
            <div className="home-section-a--text-c">
              Investasi Properti Menjanjikan di{' '}
              <span className="app-color-text-10">Lokasi Strategis!</span>
            </div>
            <div className="home-section-a--text-d">
              <span className="app-color-text-10">20</span> people are viewing this page
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-b--container">
        <div className="home-section-b--filter-container">
          <div className="title">
            Investasi Properti Menjanjikan di{' '}
            <span className="app-color-text-10">Lokasi Strategis!</span>
          </div>
          <div className="home-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Mencari apa"
              paramName="property_type_id"
              key="property_type_id"
              options={propertyTypesList}
            />
          </div>
          <div className="home-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Lokasi"
              paramName="city_id"
              key="city_id"
              options={cityList}
            />
          </div>
          <div className="home-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Tipe"
              paramName="sales_type_id"
              key="sales_type_id"
              options={salesTypesList}
            />
          </div>
          <div className="home-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Harga"
              paramName="price_selection_id"
              key="price_selection_id"
              options={priceSelectionsList}
            />
          </div>
        </div>
      </div>

      <div className="home-section-c--container">
        <div className="home-section-c--properties-container">
          {/* {properties && properties.length > 0
            ? properties.map((property) => {
                return (
                  <CardPropertyTypeI
                    key={`content-${property.id}`}
                    id={property.id}
                    title={property.title}
                    buildingArea={property.building_area}
                    fieldArea={property.field_area}
                    bedroom={property.bedroom}
                    bathroom={property.bathroom}
                    location={property.location}
                    price={property.discount_price}
                    discountPrice={property.normal_price}
                  />
                );
              })
            : null} */}

          {propertyList && propertyList.length > 0
            ? propertyList.map((property) => {
                return (
                  <CardPropertyTypeJ
                    key={`content-${property.id}`}
                    id={property?.id}
                    name={property?.name}
                    location={property?.location}
                    price={property?.price}
                    discountedPrice={property?.discounted_price}
                    lotArea={property?.lot_area}
                    floorArea={property?.floor_area}
                    bedroomTotal={property?.bedroom_total}
                    bathroomTotal={property?.bathroom_total}
                    phoneNumber={property?.phone_number}
                    cityName={property?.city_name}
                    coverImageUrl={property?.cover_image_url}
                  />
                );
              })
            : null}
        </div>

        <div className="home-section-c--properties-container-mobile-view">
          {/* {propertyList && propertyList.length > 0
            ? propertyList.map((property) => {
                return (
                  <CardPropertyTypeK
                    key={`content-${property.id}`}
                    id={property?.id}
                    name={property?.name}
                    location={property?.location}
                    price={property?.price}
                    discountedPrice={property?.discounted_price}
                    cityName={property?.city_name}
                    coverImageUrl={property?.cover_image_url}
                  />
                );
              })
            : null} */}
          {propertyList && propertyList.length > 0
            ? propertyList.map((property) => {
                return (
                  <CardPropertyTypeJ
                    key={`content-${property.id}`}
                    id={property?.id}
                    name={property?.name}
                    location={property?.location}
                    price={property?.price}
                    discountedPrice={property?.discounted_price}
                    lotArea={property?.lot_area}
                    floorArea={property?.floor_area}
                    bedroomTotal={property?.bedroom_total}
                    bathroomTotal={property?.bathroom_total}
                    phoneNumber={property?.phone_number}
                    cityName={property?.city_name}
                    coverImageUrl={property?.cover_image_url}
                  />
                );
              })
            : null}
        </div>

        <div className="d-flex flex-row justify-content-center">
          <Link to="/properties" className="home-section-c--btn-see-more">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 4L12.9 5.1L18.9 11.2H2V12.8H18.9L12.9 18.9L14 20L22 12L14 4Z"
                fill="white"
              />
            </svg>
            <span className="ms-2">Lihat Semua</span>
          </Link>
        </div>
      </div>

      <div className="home-section-d--container">
        {/* <div className="home-section-d--cta-container">
          <article className="home-section-d--cta-text">
            <h4 className="montserrat-bold text-white">
              Jadi yang pertama dapatkan update terkini properti terbaik dan strategis
            </h4>
          </article>
          <Link to="/property-cooperation" className="cta-property-cooperation">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 4L12.9 5.1L18.9 11.2H2V12.8H18.9L12.9 18.9L14 20L22 12L14 4Z"
                fill="white"
              />
            </svg>

            <p className="ms-2 txt-16px montserrat-bold m-0">Kerjasama Properti</p>
          </Link>
        </div> */}
        <div className="home-section-d--inner-container">
          <h4 className="text">
            Jadi yang pertama dapatkan update terkini properti terbaik dan strategis
          </h4>
          <Link to="/property-cooperation" className="cta-button">
            <img src={arrowRight} className="arrow-right" alt="arrow-right" />
            {/* <p className="txt-16px montserrat-bold m-0">Kerjasama Properti</p> */}
            <p className="text">Kerjasama Properti</p>
          </Link>
        </div>
      </div>
    </main>
    // <section className="d-flex flex-column justify-content-center align-items-center w-100">
    //   <div className="app-body-container">
    //     <div className="home-section-i">
    //       <h1 className="home-section-i--big-text">Best value Indonesia</h1>
    //       <h1 className="home-section-i--big-text">properties</h1>
    //       <p className="home-section-i--small-text">
    //         Selamat datang di peluang investasi properti yang menarik! Kami memiliki penawaran
    //         eksklusif untuk Anda yang sedang mencari investasi properti yang menguntungkan. Dapatkan
    //         kesempatan emas untuk memiliki properti yang terletak di lokasi strategis dengan potensi
    //         pertumbuhan nilai yang tinggi.
    //       </p>
    //       <div className="home-section-i--bottom-object" />
    //     </div>
    //     <div className="home-section-ii px-app-default">
    //       <h3 className="home-section-ii--title mb-4">
    //         Investasi Properti Menjanjikan di{' '}
    //         <span className="app-color-text-10 ">Lokasi Strategis!</span>
    //       </h3>
    //       <div className="home-section-ii--filter-container">
    //         <DropdownTypeA name="Mencari Apa" dropdownId="filter-thing" />
    //         <DropdownTypeA name="Lokasi" dropdownId="filter-location" />
    //         <DropdownTypeA name="Tipe" dropdownId="filter-type" />
    //         <DropdownTypeA name="Harga" dropdownId="filter-price" />
    //       </div>
    //       <div className="home-section-ii--property-list mb-5">
    //         {properties && properties.length > 0
    //           ? properties.map((property) => {
    //               return (
    //                 <CardPropertyTypeI
    //                   key={`content-${property.id}`}
    //                   id={property.id}
    //                   title={property.title}
    //                   buildingArea={property.building_area}
    //                   fieldArea={property.field_area}
    //                   bedroom={property.bedroom}
    //                   bathroom={property.bathroom}
    //                   location={property.location}
    //                   price={property.discount_price}
    //                   discountPrice={property.normal_price}
    //                 />
    //               );
    //             })
    //           : null}
    //         {/* <CardPropertyTypeI />
    //         <CardPropertyTypeI />
    //         <CardPropertyTypeI /> */}
    //       </div>
    //       <div className="home-section-ii--see-more mb-5">
    //         <Link to="/properties" className="home-section-ii--btn-see-more">
    //           <svg
    //             width="24"
    //             height="24"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M14 4L12.9 5.1L18.9 11.2H2V12.8H18.9L12.9 18.9L14 20L22 12L14 4Z"
    //               fill="white"
    //             />
    //           </svg>
    //           <span className="ms-2">Lihat Semua</span>
    //         </Link>
    //       </div>
    //     </div>
    //     {/* <div className="home-section-iii px-app-default">
    //       <SliderTypeA contents={newProperties} />
    //     </div> */}
    //   </div>

    //   <div className="w-100 bg-white d-flex flex-row justify-content-center align-items-center">
    //     <div className="app-body-container">
    //       <div className="d-flex flex-row justify-content-center py-5 bg-white">
    //         <div className="cta-section py-4 px-5 justify-content-between">
    //           <article className="w-50">
    //             <h4 className="montserrat-bold text-white txt-24px">
    //               Jadi yang pertama dapatkan update terkini properti terbaik dan strategis
    //             </h4>
    //           </article>
    //           <Link to="/register">
    //             <div className="cta-register">
    //               <svg
    //                 width="24"
    //                 height="24"
    //                 viewBox="0 0 24 24"
    //                 fill="none"
    //                 xmlns="http://www.w3.org/2000/svg"
    //               >
    //                 <path
    //                   d="M14 4L12.9 5.1L18.9 11.2H2V12.8H18.9L12.9 18.9L14 20L22 12L14 4Z"
    //                   fill="white"
    //                 />
    //               </svg>

    //               <p className="ms-2 txt-16px montserrat-bold m-0">Kerjasama Properti</p>
    //             </div>
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
}

export default Home;
