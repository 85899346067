/* eslint-disable import/no-unresolved */
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NotFound from 'pages/notFound';
import RootLayout from 'components/Layouts/RootLayout';

import Home from 'pages/home';
import DetailProperties from 'pages/properties/details';
import PropertyCooperation from 'pages/propertyCooperation';
import ViewOnMaps from 'pages/view-on-maps';

import 'styles/App.css';
import 'styles/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path="/properties/:id" element={<DetailProperties />} />
        <Route path="/property-cooperation" element={<PropertyCooperation />} />
      </Route>
      <Route path="/view-on-maps" element={<ViewOnMaps />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
