// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-on-maps--header {
  width: 100%;
  height: 100px;
  background-color: #003459;
  padding: 25px 20px;
  padding-left: 1.38%;
  padding-right: 1.38%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.view-on-maps--back-button-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pages/view-on-maps/view-on-maps.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".view-on-maps--header {\n  width: 100%;\n  height: 100px;\n  background-color: #003459;\n  padding: 25px 20px;\n  padding-left: 1.38%;\n  padding-right: 1.38%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.view-on-maps--back-button-icon {\n  height: 24px;\n  width: 24px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
