/* eslint-disable import/no-unresolved */
import { BehaviorSubject } from 'rxjs';
// import fetchWrapper from 'utils/fetch-wrapper';
import fetchWrapper from 'utils/fetchWrapper';

const apiUrl = 'https://qorser-carro-api.stagingapps.net';
const userSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('techpolitanWebUserToken'))
);

function setUserSubject(user) {
  userSubject.next(user);
  localStorage.setItem('techpolitanWebUserToken', JSON.stringify(user));
}

async function login(email, password, deviceId) {
  try {
    const response = await fetchWrapper.post(`${apiUrl}/users/session/login`, {
      email,
      password,
      device_id: deviceId,
      device_model: 'WEB',
      device_type: 'WEB',
    });

    if (response) {
      setUserSubject(response.data);
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function logout() {
  try {
    const response = await fetchWrapper.get(`${apiUrl}/users/session/logout`);

    if (response) {
      localStorage.removeItem('techpolitanWebUserToken');
      userSubject.next(null);
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function requestOTP(email, type = 'forget_password') {
  try {
    const response = await fetchWrapper.post(
      `${apiUrl}/users/session/resend_otp`,
      { type, email }
    );

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function verifyOTP(email, otp, type) {
  try {
    const response = await fetchWrapper.post(
      `${apiUrl}/users/session/verify_otp`,
      {
        type,
        email,
        otp,
      }
    );

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

async function newPassword(key, password, confirmPassword) {
  try {
    const response = await fetchWrapper.patch(
      `${apiUrl}/users/session/reset_password`,
      {
        key,
        password,
        confirm_password: confirmPassword,
      }
    );

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

// async function login(phone, password, deviceId) {
//   return fetchWrapper
//     .post(`${apiUrl}/users/session/login`, {
//       phone,
//       password,
//       device_id: deviceId,
//       device_model: 'WEB',
//       device_type: 'WEB',
//     })
//     .then((response) => {
//       const user = response.data;
//       setUserSubject(user);
//       return user;
//     });
// }

async function me() {
  return fetchWrapper.get(`${apiUrl}/users/profile/member`);
}

// async function logout() {
//   await fetchWrapper.get(`${apiUrl}/users/session/logout`);

//   // remove user from local storage, publish null to user subscribers and redirect to login page
//   localStorage.removeItem('techpolitanWebUserToken');
//   userSubject.next(null);
//   return true;
//   // navigate("/");
// }

async function logoutNoApi() {
  // remove user from local storage, publish null to user subscribers and redirect to login page
  localStorage.removeItem('techpolitanWebUserToken');
  userSubject.next(null);
  return true;
  // navigate("/");
}

async function register(email, fullName, phone, password, confirmPassword) {
  try {
    const response = await fetchWrapper.post(`${apiUrl}/users/session/signup`, {
      email,
      full_name: fullName,
      phone: `62${phone}`,
      password,
      confirm_password: confirmPassword,
    });

    if (response) {
      return response;
    }
  } catch (error) {
    return error;
  }
  return null;
}

const authService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  login,
  me,
  logout,
  logoutNoApi,
  register,
  requestOTP,
  verifyOTP,
  newPassword,
  setUserSubject,
};

export default authService;
