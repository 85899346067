/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    className="dropdown-type-a--toggle"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div>{children}</div>
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.76 7.27358C5.44238 7.27393 5.13998 7.15158 4.93008 6.9378L0.220799 2.13694C-0.0887669 1.80606 -0.0471988 1.31406 0.314423 1.0288C0.676045 0.743538 1.22545 0.769362 1.55184 1.08696L5.66976 5.28513C5.69254 5.30845 5.72543 5.32182 5.76 5.32182C5.79457 5.32182 5.82745 5.30845 5.85024 5.28513L9.96816 1.08696C10.1749 0.865974 10.4989 0.762758 10.8148 0.817253C11.1306 0.871749 11.3888 1.07541 11.4894 1.34942C11.59 1.62343 11.5172 1.92481 11.2992 2.13694L6.59136 6.93607C6.3811 7.15038 6.07829 7.27331 5.76 7.27358Z"
        fill="#333333"
      />
    </svg>
  </div>
));

function DropdownTypeA({ name, paramName, dropdownId, options, handleDropdown }) {
  const [selectedOption, setSelectedOption] = useState(name);

  const handleOptionSelect = (label, value) => {
    setSelectedOption(label);
    handleDropdown(paramName, value);
  };

  return (
    <Dropdown className="w-100">
      <Dropdown.Toggle as={CustomToggle} id={dropdownId}>
        {selectedOption}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-type-a--menu">
        {options
          ? options.map((option) => (
              <Dropdown.Item
                key={option.id}
                eventKey={option.label}
                onClick={() => handleOptionSelect(option.label, option.id)}
              >
                {option.label}
              </Dropdown.Item>
            ))
          : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const optionsProps = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

DropdownTypeA.propTypes = {
  name: PropTypes.string.isRequired,
  paramName: PropTypes.string.isRequired,
  dropdownId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionsProps).isRequired,
  handleDropdown: PropTypes.func.isRequired,
};

export default DropdownTypeA;
