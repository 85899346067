/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthUserContext from 'utils/contexts';
// import { requestPermission } from './firebase';

import NotFound from 'pages/notFound';
import RootLayout from 'components/Layouts/RootLayout';

import Home from 'pages/home';
import Properties from 'pages/properties';
import DetailProperties from 'pages/properties/details';
import PropertyCooperation from 'pages/propertyCooperation';

// import Login from 'pages/login';
// import Register from 'pages/register';
// import LostPassword from 'pages/lostPassword';
// import VerifyOtp from 'pages/verifyOtp';
// import NewPassword from 'pages/newPassword';
// import AccountActivation from 'pages/accountActivation';

import 'styles/App.css';
import 'styles/responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  // const firstRun = useRef(true);
  const [currentUser, setCurrentUser] = useState(null);
  const authUserContextValue = useMemo(
    () => ({ currentUser, setCurrentUser }),
    [currentUser, setCurrentUser],
  );

  return (
    <AuthUserContext.Provider value={authUserContextValue}>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/properties/:id" element={<DetailProperties />} />
          <Route path="/property-cooperation" element={<PropertyCooperation />} />
        </Route>
        {/* <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/lost-password" element={<LostPassword />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/account-activation" element={<AccountActivation />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthUserContext.Provider>
  );
}

export default App;
