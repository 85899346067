/* eslint-disable import/no-unresolved */
import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'assets/logo--techpolitan-footer.svg';
import './Footer.modules.css';

export default function Footer() {
  return (
    <footer className="app-footer-container">
      <div className="app-footer__company-info">
        <div className="logo">
          <img src={logo} alt="logo-white" />
        </div>
        <div className="social-media-list">
          <Link to="/">
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.8546 2.7967C20.088 3.13748 19.2629 3.36735 18.3972 3.47022C19.2811 2.94106 19.9589 2.10286 20.2785 1.10295C19.4517 1.59359 18.5365 1.94961 17.5615 2.1418C16.7813 1.31038 15.6692 0.790527 14.4386 0.790527C12.076 0.790527 10.1604 2.70611 10.1604 5.06915C10.1604 5.40401 10.1981 5.7304 10.2713 6.04409C6.71533 5.86544 3.56234 4.16237 1.45203 1.57369C1.08373 2.2053 0.872912 2.94021 0.872912 3.72507C0.872912 5.20927 1.62814 6.51906 2.77622 7.28614C2.07518 7.26371 1.4152 7.07109 0.838199 6.75063C0.837775 6.76841 0.837775 6.78661 0.837775 6.80481C0.837775 8.87745 2.31267 10.6063 4.27016 11C3.91117 11.0974 3.53313 11.1499 3.14282 11.1499C2.86681 11.1499 2.59884 11.1232 2.33764 11.0729C2.88247 12.7725 4.46235 14.0099 6.33433 14.0442C4.87002 15.1919 3.02556 15.876 1.02066 15.876C0.675639 15.876 0.334856 15.8557 0 15.8159C1.89399 17.0304 4.14273 17.7382 6.55869 17.7382C14.4289 17.7382 18.732 11.2189 18.732 5.56487C18.732 5.37945 18.7282 5.19446 18.7202 5.01073C19.5559 4.40875 20.2814 3.6548 20.8546 2.7967Z"
                fill="white"
              />
            </svg>
          </Link>

          <Link to="/" className="ms-3">
            <svg
              width="10"
              height="21"
              viewBox="0 0 10 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.6016 6.62219H6.36523V4.4996C6.36523 3.70247 6.89354 3.51662 7.26565 3.51662C7.63692 3.51662 9.54953 3.51662 9.54953 3.51662V0.0122766L6.40417 0C2.91252 0 2.11793 2.61366 2.11793 4.28624V6.62219H0.0986328V10.2332H2.11793C2.11793 14.8674 2.11793 20.4512 2.11793 20.4512H6.36523C6.36523 20.4512 6.36523 14.8124 6.36523 10.2332H9.23119L9.6016 6.62219Z"
                fill="white"
              />
            </svg>
          </Link>
        </div>
        <p className="web-name">Techpolitan Property Marketplace</p>
        <p className="trademark">© 2024 PT Techpolitan | All Rights Reserved</p>
      </div>
      <nav className="app-footer__navigation-container">
        <div className="app-footer__navigation">
          <p className="title">Our Product</p>
          <ul className="list">
            <li>
              <Link to="/products" className="montserrat-medium text-white txt-14px">
                Villa
              </Link>
            </li>
            <li>
              <Link to="/products" className="montserrat-medium text-white txt-14px">
                House
              </Link>
            </li>
            <li>
              <Link to="/products" className="montserrat-medium text-white txt-14px">
                Warehouse
              </Link>
            </li>
          </ul>
        </div>
        <div className="app-footer__navigation">
          <p className="title">Company</p>
          <ul className="list">
            <li>
              <Link to="/about-us" className="montserrat-medium text-white txt-14px">
                Tentang Kami
              </Link>
            </li>
            <li>
              <Link to="/support" className="montserrat-medium text-white txt-14px">
                Support
              </Link>
            </li>
          </ul>
        </div>
        <div className="app-footer__navigation">
          <p className="title">Resources</p>
          <ul className="list">
            <li>
              <Link to="/copyright-policy" className="montserrat-medium text-white txt-14px">
                Copyright Policy
              </Link>
            </li>
            <li>
              <Link to="/term-of-service" className="montserrat-medium text-white txt-14px">
                Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/faq" className="montserrat-medium text-white txt-14px">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/faq" className="montserrat-medium text-white txt-14px">
                System Status
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </footer>
  );
}
