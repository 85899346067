/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import propertyService from 'services/property.service';
import CardPropertyTypeJ from 'components/Cards/CardPropertyTypeJ';
import { useWindowSize } from '../../hooks/useWindowSize';
//import CardPropertyTypeK from 'components/Cards/CardPropertyTypeK';
import DropdownTypeA from 'components/Dropdowns/DropdownTypeA';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './properties.modules.css';

function Properties() {
  const windowSize = useWindowSize();
  const [payload, setPayload] = useState({
    page: 1,
    limit: 9,
    sort_by: 'id',
    is_asc: true,
    search: '',
    property_type_id: null,
    city_id: null,
    sales_type_id: null,
    price_selection_id: null,
  });

  const [propertyList, setPropertyList] = useState([]);
  const [propertyListTotal, setPropertyListTotal] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [propertyTypesList, setPropertyTypesList] = useState([]);
  const [salesTypesList, setSalesTypesList] = useState([]);
  const [priceSelectionsList, setPriceSelectionsList] = useState([]);
  const [seeMore, setSeeMore] = useState(0);

  const calculateWindowSize = (windowWidth) => {
    if (windowWidth <= 600) {
      setPayload((prevValue) => {
        return { ...prevValue, limit: 5 };
      });
      const payloadNew = { ...payload, limit: windowSize.width <= 600 ? 5 : payload.limit };
      fetchProperty(payloadNew);
    }
  };

  async function fetchProperty(payloadData) {
    try {
      const response = await propertyService.propertyList(payloadData);
      setPropertyList([...response?.data?.content]);
      setPropertyListTotal(response?.data?.total_all_elements);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSeeMoreProperty() {
    try {
      const payloadData = { ...payload, limit: windowSize.width <= 600 ? 5 : payload.limit };
      const response = await propertyService.propertyList(payloadData);
      setPropertyList([...response?.data?.content, ...propertyList]);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCities() {
    try {
      const response = await propertyService.cities();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });

      setCityList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchPropertyTypes() {
    try {
      const response = await propertyService.propertyTypes();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setPropertyTypesList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchSalesTypes() {
    try {
      const response = await propertyService.salesTypes();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setSalesTypesList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchPriceSelections() {
    try {
      const response = await propertyService.priceSelections();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setPriceSelectionsList(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  function fetchSeeMore() {
    setPayload({ ...payload, page: payload.page + 1 });
    setSeeMore(seeMore + 1);
  }

  function handleDropdown(param, value) {
    setPayload({ ...payload, [param]: value });
    fetchProperty({ ...payload, [param]: value });
  }

  useEffect(() => {
    fetchProperty(payload);
  }, []);

  useEffect(() => {
    fetchCities();
    fetchPropertyTypes();
    fetchSalesTypes();
    fetchPriceSelections();
  }, []);

  useEffect(() => {
    if (seeMore > 0) {
      fetchSeeMoreProperty();
    }
  }, [seeMore]);

  useEffect(() => {
    calculateWindowSize(windowSize.width);
  }, [windowSize]);

  return (
    <main className="main-container">
      <div className="properties-section-a--container">
        <div className="properties-section-a-i-inner-container">
          <h1 className="properties-section-a--text-a">Best value Indonesia</h1>
          <h1 className="properties-section-a--text-a">properties</h1>
        </div>
        <div className="properties-section-a-ii-inner-container">
          <div className="properties-section-a--object">
            <div className="properties-section-a--text-b">
              Investasi Properti Menjanjikan di{' '}
              <span className="app-color-text-10">Lokasi Strategis!</span>
            </div>
            <div className="properties-section-a--text-c">
              <span className="app-color-text-10">20</span> people are viewing this page
            </div>
          </div>
        </div>
      </div>
      <div className="properties-section-b--container">
        <div className="properties-section-b--filter-container">
          <div className="title">
            Investasi Properti Menjanjikan di{' '}
            <span className="app-color-text-10">Lokasi Strategis!</span>
          </div>
          <div className="properties-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Mencari apa"
              paramName="property_type_id"
              key="property_type_id"
              options={propertyTypesList}
            />
          </div>
          <div className="properties-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Lokasi"
              paramName="city_id"
              key="city_id"
              options={cityList}
            />
          </div>
          <div className="properties-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Tipe"
              paramName="sales_type_id"
              key="sales_type_id"
              options={salesTypesList}
            />
          </div>
          <div className="properties-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Harga"
              paramName="price_selection_id"
              key="price_selection_id"
              options={priceSelectionsList}
            />
          </div>
        </div>
      </div>
      <div className="properties-section-c--container">
        <div className="properties-section-c--properties-container">
          {/* {properties && properties.length > 0
            ? properties.map((property) => {
                return (
                  <CardPropertyTypeI
                    key={`content-${property.id}`}
                    id={property.id}
                    title={property.title}
                    buildingArea={property.building_area}
                    fieldArea={property.field_area}
                    bedroom={property.bedroom}
                    bathroom={property.bathroom}
                    location={property.location}
                    price={property.discount_price}
                    discountPrice={property.normal_price}
                  />
                );
              })
            : null} */}
          {propertyList && propertyList.length > 0
            ? propertyList.map((property) => {
                return (
                  <CardPropertyTypeJ
                    key={`content-${property.id}`}
                    id={property?.id}
                    name={property?.name}
                    location={property?.location}
                    price={property?.price}
                    discountedPrice={property?.discounted_price}
                    lotArea={property?.lot_area}
                    floorArea={property?.floor_area}
                    bedroomTotal={property?.bedroom_total}
                    bathroomTotal={property?.bathroom_total}
                    phoneNumber={property?.phone_number}
                    cityName={property?.city_name}
                    coverImageUrl={property?.cover_image_url}
                  />
                );
              })
            : null}
        </div>

        <div className="properties-section-c--properties-container-mobile-view">
          {/* {propertyList && propertyList.length > 0
            ? propertyList.map((property) => {
                return (
                  <CardPropertyTypeK
                    key={`content-${property.id}`}
                    id={property?.id}
                    name={property?.name}
                    location={property?.location}
                    price={property?.price}
                    discountedPrice={property?.discounted_price}
                    cityName={property?.city_name}
                    coverImageUrl={property?.cover_image_url}
                  />
                );
              })
            : null} */}
          {propertyList && propertyList.length > 0
            ? propertyList.map((property) => {
                return (
                  <CardPropertyTypeJ
                    key={`content-${property.id}`}
                    id={property?.id}
                    name={property?.name}
                    location={property?.location}
                    price={property?.price}
                    discountedPrice={property?.discounted_price}
                    lotArea={property?.lot_area}
                    floorArea={property?.floor_area}
                    bedroomTotal={property?.bedroom_total}
                    bathroomTotal={property?.bathroom_total}
                    phoneNumber={property?.phone_number}
                    cityName={property?.city_name}
                    coverImageUrl={property?.cover_image_url}
                  />
                );
              })
            : null}
        </div>

        {propertyListTotal === propertyList.length ? null : (
          <div className="d-flex flex-row justify-content-center">
            <button
              type="button"
              onClick={fetchSeeMore}
              className="properties-section-c--btn-see-more"
            >
              Lihat Lebih
            </button>
          </div>
        )}
      </div>
    </main>
  );
}

export default Properties;
