// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 900px) {
  .login-page-container,
  .signup-page-container,
  .account-activation-page-container,
  .reset-password-page-container,
  .verify-otp-page-container,
  .new-password-page-container {
    flex-direction: column;
  }

  .login-section-left,
  .login-section-right,
  .signup-section-left,
  .account-activation-section-left,
  .account-activation-section-right,
  .reset-password-section-left,
  .reset-password-section-right,
  .verify-otp-section-left,
  .verify-otp-section-right,
  .new-password-section-left,
  .new-password-section-right {
    width: 100%;
    height: 50%;
  }

  .signup-section-right {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-bottom: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .dropdown-type-a--toggle {
    font-size: 0.75rem;
  }

  .custom-google-maps__container {
    height: 219.63px;
  }
}

@media only screen and (max-width: 800px) {
  .cta-property-cooperation__btn {
    padding: 10.67px 11.33px;
  }

  .cta-property-cooperation__btn .text {
    margin-left: 5px;
    font-size: 0.75rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/responsive.css"],"names":[],"mappings":"AAAA;EACE;;;;;;IAME,sBAAsB;EACxB;;EAEA;;;;;;;;;;;IAWE,WAAW;IACX,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;AACF","sourcesContent":["@media only screen and (max-width: 900px) {\n  .login-page-container,\n  .signup-page-container,\n  .account-activation-page-container,\n  .reset-password-page-container,\n  .verify-otp-page-container,\n  .new-password-page-container {\n    flex-direction: column;\n  }\n\n  .login-section-left,\n  .login-section-right,\n  .signup-section-left,\n  .account-activation-section-left,\n  .account-activation-section-right,\n  .reset-password-section-left,\n  .reset-password-section-right,\n  .verify-otp-section-left,\n  .verify-otp-section-right,\n  .new-password-section-left,\n  .new-password-section-right {\n    width: 100%;\n    height: 50%;\n  }\n\n  .signup-section-right {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    padding-bottom: 5%;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .dropdown-type-a--toggle {\n    font-size: 0.75rem;\n  }\n\n  .custom-google-maps__container {\n    height: 219.63px;\n  }\n}\n\n@media only screen and (max-width: 800px) {\n  .cta-property-cooperation__btn {\n    padding: 10.67px 11.33px;\n  }\n\n  .cta-property-cooperation__btn .text {\n    margin-left: 5px;\n    font-size: 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
