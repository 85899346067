/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import propertyService from 'services/property.service';
import { BackFromViewOnMapsButton, ResetButton } from 'components/Buttons/Button';
import arrowLeft from 'assets/techpolitan-arrow-left-white.svg';
import 'styles/pages/view-on-maps/view-on-maps.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function ViewOnMaps() {
  const navigate = useNavigate();
  const [propertyList, setPropertyList] = useState([]);

  async function fetchList() {
    const response = await propertyService.propertyListForMap();
    if (response?.status_code === 200 && response?.message === 'Sukses') {
      setPropertyList(response?.data);
    }
  }

  useEffect(() => {
    fetchList();
  }, []);
  return (
    <>
      <header className="view-on-maps--header">
        <BackFromViewOnMapsButton type="button" onClick={() => navigate(-1)}>
          <img src={arrowLeft} alt="arrow-left-icon" className="view-on-maps--back-button-icon" />{' '}
          <span>Kembali ke list view</span>
        </BackFromViewOnMapsButton>
        <ResetButton type="button">Reset</ResetButton>
      </header>
      {propertyList ? (
        <MapContainer
          center={[-6.936816, 107.606821]}
          zoom={10}
          style={{ height: 'calc(100vh - 100px)' }}
          scrollWheelZoom={false}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl position="topright" />
          {propertyList.map((property) => (
            <Marker key={property.id} position={[property.coords_lat, property.coords_lng]}>
              <Popup>
                <div>
                  <h3>{property.name}</h3>
                  <img
                    src={property.cover_image_url}
                    alt={property.name}
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <p>
                    Price:{' '}
                    {property.price.toLocaleString('en-US', { style: 'currency', currency: 'IDR' })}
                  </p>
                  <p>Updated at: {new Date(property.updated_at).toLocaleString()}</p>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      ) : null}
    </>
  );
}

export default ViewOnMaps;
