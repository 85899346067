const dummyProducts = [
  {
    id: 1,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 2,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 3,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 4,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 5,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 6,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 7,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 8,
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
];

const sortData = [
  { id: 0, property: 0, direction: 'asc', name: 'Termurah' },
  { id: 1, property: 0, direction: 'desc', name: 'Termahal' },
  { id: 2, property: 1, direction: 'asc', name: 'Terlama' },
  { id: 3, property: 1, direction: 'desc', name: 'Terbaru' },
];

const statusData = [
  { id: 0, name: 'Kalah' },
  { id: 1, name: 'Menang' },
];

const transmissions = [
  { id: "'AT'", name: 'AT' },
  { id: "'MT'", name: 'MT' },
];

const sliderThree = [
  {
    name: 'BMW A',
    transmission: 'AT',
    odometer: '10000',
    favorite: false,
    car_id: 121,
    manufacture_year: '2023',
    tax_expire_date: '07/05/2028',
    start_price: '',
    buy_now_price: '',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/23874938-0',
    status: false,
  },
  {
    name: 'Toyata Supra',
    transmission: 'MT',
    odometer: '2379',
    favorite: false,
    car_id: 41,
    manufacture_year: '1990',
    tax_expire_date: '03/01/2024',
    start_price: '',
    buy_now_price: '',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/312893193987-0',
    status: false,
  },
  {
    name: 'Toyota Max',
    transmission: 'AT',
    odometer: '10000',
    favorite: false,
    car_id: 101,
    manufacture_year: '2010',
    tax_expire_date: '03/19/2024',
    start_price: '',
    buy_now_price: '',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/2318391827398-0',
    status: false,
  },
  {
    name: 'Toyata N MAX',
    transmission: 'MT',
    odometer: '10000',
    favorite: false,
    car_id: 141,
    manufacture_year: '2002',
    tax_expire_date: '03/30/2024',
    start_price: '20.000.000',
    buy_now_price: '',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/38397487293847-0',
    status: true,
  },
];

const sliderTwo = [
  {
    id: '1',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 'w',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: 'c',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: '1x',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: '1s',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: '1ss',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: '1aa',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
  {
    id: '1xx',
    image: '',
    name: 'Mercedes Benz-GLA 200 AMG',
    odometer: '33.872',
    transmission: 'AT',
    productionYear: '2006',
    expiredStnk: '24/06/2024',
    currentBid: 'Rp 479.030.000',
    buyItNow: 'Rp 492.000.000',
  },
];

const brandList = [
  {
    id: 801,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 802,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 803,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 804,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 805,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 806,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 807,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 808,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
  {
    id: 809,
    name: 'BMW',
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/brd/bmw',
  },
];

const carProps = {
  police_number: 'D 1445 ABC',
  brand_name: 'MERCEDES BENZ',
  vehicle_series: 'C 200',
  manufacture_year: '2018',
  transmission: 'AUTOMATIC',
  engine_capacity: '1998 CC',
  fuel_type: 'BENSIN',
  color: 'ABU METALIK',
  structure_number: 'MHL204048AJ002738',
  engine_number: '27186030033854',
  odometer: '91689 KM',
  seater: '5',
  is_stnk: true,
  is_bpkb: true,
  is_facture: false,
  is_ktp_photo: false,
  is_kwt_blanko: false,
  is_form_a: false,
  tnkb_color: 'HITAM',
  tax_expire_date: '06/07/2024',
};

const bidHistorySample = [{ bid: 'Rp 400.000.000', user: 'anonim' }];

const carImages = [
  {
    original: 'https://placehold.co/600x400',
    thumbnail: 'https://placehold.co/400x200',
    originalHeight: '100%',
    originalWidth: 'auto',
  },
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    originalHeight: '100%',
    originalWidth: 'auto',
    //https://placehold.co/600x400
    //https://placehold.co/400
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
    originalHeight: '100%',
    originalWidth: 'auto',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
    originalHeight: '100%',
    originalWidth: 'auto',
  },
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    originalHeight: '100%',
    originalWidth: 'auto',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
    originalHeight: '100%',
    originalWidth: 'auto',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
    originalHeight: '100%',
    originalWidth: 'auto',
  },
];

const invoices = [
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: false,
  },
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: false,
  },
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: false,
  },
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: false,
  },
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: false,
  },
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: false,
  },
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: false,
  },
  {
    brand_name: 'Toyota',
    buy_now_price: '100.000.000',
    car_id: 161,
    favorite: false,
    image_url: 'https://carex-appschef.s3.ap-southeast-3.amazonaws.com/product/918309128301-0',
    manufacture_year: '2000',
    name: 'Toyota Super',
    odometer: '10000',
    start_date: '2024-03-21 13:55:00',
    payment_date: '2024-03-21 14:55:00',
    start_price: '45.000.000',
    tax_expire_date: '2024/04/30',
    last_price: '479.000.000',
    transmission: 'MT',
    status: true,
  },
];

const notifications = [
  { title: 'Bid di lelang', content: 'Bid tertinggi Rp 50.000.00', date: '2024-03-21 14:55:00' },
  { title: 'Bid di lelang', content: 'Bid tertinggi Rp 50.000.00', date: '2024-03-21 14:55:00' },
  { title: 'Bid di lelang', content: 'Bid tertinggi Rp 50.000.00', date: '2024-03-21 14:55:00' },
  { title: 'Bid di lelang', content: 'Bid tertinggi Rp 50.000.00', date: '2024-03-21 14:55:00' },
  { title: 'Bid di lelang', content: 'Bid tertinggi Rp 50.000.00', date: '2024-03-21 14:55:00' },
  { title: 'Bid di lelang', content: 'Bid tertinggi Rp 50.000.00', date: '2024-03-21 14:55:00' },
  { title: 'Bid di lelang', content: 'Bid tertinggi Rp 50.000.00', date: '2024-03-21 14:55:00' },
];

const faqs = [
  {
    id: 1,
    question: 'Apa yang membedakan CAREX dari perusahaan lelang mobil lainnya?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 2,
    question: 'Bagaimana cara dealer bisa ikut serta dalam lelang mobil CAREX?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 3,
    question: 'Apakah semua mobil yang dilelang oleh CAREX memiliki kondisi yang baik?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 4,
    question: 'Bagaimana cara mengetahui daftar mobil yang akan dilelang oleh CAREX?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 5,
    question: 'Apa yang membedakan CAREX dari perusahaan lelang mobil lainnya?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 6,
    question: 'Bagaimana cara dealer bisa ikut serta dalam lelang mobil CAREX?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 7,
    question: 'Apakah semua mobil yang dilelang oleh CAREX memiliki kondisi yang baik?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 8,
    question: 'Bagaimana cara mengetahui daftar mobil yang akan dilelang oleh CAREX?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 9,
    question: 'Apa yang membedakan CAREX dari perusahaan lelang mobil lainnya?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 10,
    question: 'Bagaimana cara saya bisa ikut serta dalam lelang mobil CAREX?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 11,
    question: 'Apakah semua mobil yang dilelang oleh CAREX memiliki kondisi yang baik?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
  {
    id: 12,
    question: 'Apakah semua mobil yang dilelang oleh CAREX memiliki kondisi yang baik?',
    answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer elit turpis,
    tempor at elit quis, dapibus malesuada justo. Nam eu porttitor erat. Nulla
    malesuada suscipit sem, ut bibendum eros lobortis gravida. Duis dictum, mauris
    id hendrerit ornare, nisi odio venenatis lorem, vitae gravida velit magna et
    risus. Etiam sed sem id orci tristique elementum eget eget mauris. In eget
    tellus sapien. Duis cursus facilisis magna vel porta. Mauris mollis neque magna,
    scelerisque varius mi ornare non. Aenean vel magna lacus. Maecenas vestibulum id
    nisi id volutpat. Vivamus molestie eu libero eu varius.`,
  },
];

const newProperties = [
  {
    id: 1,
    title: 'Rose Garden',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '325',
    field_area: '466',
    bedroom: '4',
    bathroom: '3',
    location: 'Dago Pakar, Bandung',
    normal_price: '8.50',
    discount_price: '4.92',
  },
  {
    id: 2,
    title: 'Tanah Kavling',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '0',
    field_area: '430',
    bedroom: '0',
    bathroom: '0',
    location: 'Lembang, Bandung',
    normal_price: '5',
    discount_price: '2.58',
  },
  {
    id: 3,
    title: 'Tanah Kavling Strategis',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '0',
    field_area: '1650',
    bedroom: '0',
    bathroom: '0',
    location: 'Soekarno Hatta, Bandung',
    normal_price: '40',
    discount_price: '25.68',
  },
  {
    id: 4,
    title: 'Gudang Point',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '240',
    field_area: '540',
    bedroom: '0',
    bathroom: '0',
    location: 'Soekarno Hatta, Bandung',
    normal_price: '28.10',
    discount_price: '21',
  },
  {
    id: 5,
    title: 'Ruko Strategis',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '0',
    field_area: '1650',
    bedroom: '0',
    bathroom: '0',
    location: 'Soekarno Hatta, Bandung',
    normal_price: '40.10',
    discount_price: '25.68',
  },
  {
    id: 6,
    title: 'Gudang Sharon',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '370',
    field_area: '560',
    bedroom: '0',
    bathroom: '0',
    location: 'Ujungberung, Bandung',
    normal_price: '30.20',
    discount_price: '23',
  },
];

const properties = [
  {
    id: 1,
    title: 'Tanah Kavling Strategis',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '325',
    field_area: '466',
    bedroom: '4',
    bathroom: '3',
    location: 'Lembang, Bandung',
    normal_price: '8.50',
    discount_price: '5.92',
  },
  {
    id: 2,
    title: 'Rumah Modern',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '325',
    field_area: '466',
    bedroom: '0',
    bathroom: '0',
    location: 'Soekarno Hatta, Bandung',
    normal_price: '7.50',
    discount_price: '3.92',
  },
  {
    id: 3,
    title: 'Sharon Residence',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '326',
    field_area: '466',
    bedroom: '4',
    bathroom: '3',
    location: 'Dago Pakar, Bandung',
    normal_price: '5.50',
    discount_price: '2.92',
  },
  {
    id: 4,
    title: 'Tanah Kavling Strategis',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '325',
    field_area: '466',
    bedroom: '4',
    bathroom: '3',
    location: 'Lembang, Bandung',
    normal_price: '8.50',
    discount_price: '5.92',
  },
  {
    id: 5,
    title: 'Rumah Modern',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '325',
    field_area: '466',
    bedroom: '0',
    bathroom: '0',
    location: 'Soekarno Hatta, Bandung',
    normal_price: '7.50',
    discount_price: '3.92',
  },
  {
    id: 6,
    title: 'Sharon Residence',
    description:
      'Rumah dengan konsep modern, minimalis dan fungsional. Rumah ini memiliki arsitektur yang modern dengan garis-garis bersih, bentuk geometris yang sederhana, dan penggunaan material...',
    building_area: '326',
    field_area: '466',
    bedroom: '4',
    bathroom: '3',
    location: 'Dago Pakar, Bandung',
    normal_price: '5.50',
    discount_price: '2.92',
  },
];

const priceTypeOptions = [
  { id: 1, label: 'Terendah' },
  { id: 2, label: 'Tertinggi' },
];

const propertyTypeOptions = [
  { id: 1, label: 'Gudang' },
  { id: 2, label: 'Ruko' },
  { id: 3, label: 'Rumah Tinggal' },
  { id: 4, label: 'Apartemen' },
];

const locationOptions = [
  { id: 1, label: 'Bandung' },
  { id: 2, label: 'Jakarta' },
  { id: 3, label: 'Surabaya' },
];

export {
  properties,
  newProperties,
  faqs,
  carImages,
  bidHistorySample,
  brandList,
  carProps,
  dummyProducts,
  sliderTwo,
  sliderThree,
  sortData,
  statusData,
  transmissions,
  invoices,
  notifications,
  locationOptions,
  propertyTypeOptions,
  priceTypeOptions,
};
