/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import defaultImage from 'assets/pexels-pixabay-164558 2.png';
import { shortenNumber } from 'utils/common';
import { Link } from 'react-router-dom';

function CardPropertyTypeK({
  id,
  name,
  location,
  price,
  discountedPrice,
  cityName,
  coverImageUrl,
}) {
  return (
    <div className="card-property-type-k-container">
      <div className="card-property-type-k-header">
        <Link to={`/properties/${id}`} className="card-property-type-k-image-container">
          <img src={coverImageUrl || defaultImage} alt="property" className="w-100" />
        </Link>
      </div>

      <div className="card-property-type-k-body">
        <Link to={`/properties/${id}`} className="card-property-type-k-body--title">
          {name}
        </Link>

        <div className="card-property-type-k-body--location">
          <svg
            width="10"
            height="14"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00001 0.25C5.17732 0.252216 3.42993 0.977256 2.1411 2.26609C0.852264 3.55492 0.127223 5.30232 0.125007 7.125C0.122855 8.61454 0.60947 10.0636 1.51019 11.25C1.51019 11.25 1.69769 11.4966 1.72782 11.5323L7.00001 17.75L12.2744 11.5296C12.3021 11.4963 12.4898 11.2498 12.4898 11.2498L12.4908 11.2483C13.3909 10.0623 13.8772 8.61386 13.875 7.125C13.8728 5.30232 13.1478 3.55492 11.8589 2.26609C10.5701 0.977256 8.82269 0.252216 7.00001 0.25ZM7.00001 9.625C6.50555 9.625 6.0222 9.47838 5.61108 9.20367C5.19996 8.92897 4.87953 8.53852 4.69031 8.08171C4.50109 7.62489 4.45158 7.12223 4.54804 6.63727C4.64451 6.15232 4.88261 5.70686 5.23224 5.35723C5.58187 5.0076 6.02733 4.7695 6.51228 4.67304C6.99723 4.57657 7.4999 4.62608 7.95672 4.8153C8.41353 5.00452 8.80398 5.32495 9.07868 5.73607C9.35338 6.1472 9.50001 6.63055 9.50001 7.125C9.49926 7.78781 9.23563 8.42326 8.76695 8.89194C8.29827 9.36062 7.66282 9.62426 7.00001 9.625Z"
              fill="#333333"
            />
          </svg>

          <span className="ms-2 app-color-text-1 card-property-type-i-body--location-text">
            {cityName}
          </span>
        </div>

        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <div>
            <div className="card-property-type-i-body--price-text-i">{`Rp ${shortenNumber(price)}`}</div>
            <div className="card-property-type-i-body--price-text-ii">
              {`Rp ${shortenNumber(discountedPrice)}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CardPropertyTypeK.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  discountedPrice: PropTypes.number.isRequired,
  cityName: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string.isRequired,
};

export default CardPropertyTypeK;
