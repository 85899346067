/* eslint-disable import/no-unresolved */
import authService from 'services/auth.service';

const apiUrl = 'https://qorser-carro-api.stagingapps.net';

function authToken() {
  return JSON.parse(localStorage.getItem('techpolitanWebUserToken'));
}

function authHeader(url) {
  const user = JSON.parse(localStorage.getItem('techpolitanWebUserToken'));
  const token = user && user.access_token ? user.access_token : '';
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(apiUrl);
  if (isLoggedIn && isApiUrl) {
    // return { Authorization: `Bearer ${token}` };
    return {
      token: `${token}`,
    };
  }
}

async function handleResponse(response) {
  const isJson = response.headers?.get('content-type')?.includes('application/json');
  const data = isJson ? await response.json() : null;

  // check for error response
  if (!response.ok) {
    if ([401, 403].includes(response.status) && authToken()) {
      authService.logoutNoApi();
    }

    // get error message from body or default to response status
    const error = (data && data.message) || response.status;
    return Promise.reject(error);
  }

  return data;
}

function request(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}

const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
  patch: request('PATCH'),
};

export default fetchWrapper;
