import styled from 'styled-components';

export const Button = styled.button`
  color: #bf4f74;
  font-size: 1rem;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid #bf4f74;
  border-radius: 3px;
  cursor: pointer;
`;

export const ViewOnMapsButton = styled(Button)`
  color: #ffffff;
  background-color: #00a8e8;
  border-radius: 5px;
  border: none;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: '-0.43px';
  margin: 0;
  padding: 12.5px 22px;
  width: 157px;

  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: #edf0f2;
    border: 1px solid #edf0f2;
    color: #9c9d9e;
    cursor: not-allowed;
  }
`;

export const BackFromViewOnMapsButton = styled(Button)`
  color: #ffffff;
  background-color: #003459;
  border-radius: 5px;
  border: 1px solid #ffffff;
  font-size: 1.063rem;
  font-weight: 600;
  line-height: 20.72px;
  margin: 0;
  padding: 13px 9.39px;
  width: 258px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: #edf0f2;
    border: 1px solid #edf0f2;
    color: #9c9d9e;
    cursor: not-allowed;
  }
`;

export const ResetButton = styled(Button)`
  color: #ffffff;
  background-color: #003459;
  border-radius: 5px;
  border: 1px solid #ffffff;
  font-size: 1.063rem;
  font-weight: 600;
  line-height: 20.72px;
  margin: 0;
  padding: 14px 9.39px;
  width: 90px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: #edf0f2;
    border: 1px solid #edf0f2;
    color: #9c9d9e;
    cursor: not-allowed;
  }
`;
