import { useState, useEffect } from 'react';
import loadGoogleMaps from 'utils/load-google-maps';

function useGoogleMaps(apiKey) {
  const [maps, setMaps] = useState(null);

  useEffect(() => {
    let isMounted = true;

    loadGoogleMaps(apiKey)
      .then((googleMaps) => {
        if (isMounted) {
          setMaps(googleMaps);
        }
      })
      .catch((error) => {
        console.error('Failed to load Google Maps:', error);
      });

    return () => {
      isMounted = false;
    };
  }, [apiKey]);

  return maps;
}

export default useGoogleMaps;
