/* eslint-disable import/no-unresolved */
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'components/Headers/Header';
import Footer from 'components/Footers/Footer';
import ScrollToTop from 'components/Customs/ScrollToTop';

export default function RootLayout() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
