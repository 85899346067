import React, { useEffect, useRef } from 'react';
import useGoogleMaps from '../../hooks/useGoogleMaps';

function CustomGmaps({ apiKey, options, onMapLoad, polygons }) {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const maps = useGoogleMaps(apiKey);

  useEffect(() => {
    if (maps && mapRef.current) {
      mapInstance.current = new maps.Map(mapRef.current, options);
      if (onMapLoad) {
        onMapLoad(mapInstance.current);
      }
    }
  }, [maps, options, onMapLoad]);

  useEffect(() => {
    if (maps && mapInstance.current && polygons) {
      // Remove existing polygons
      if (mapInstance.current.polygons) {
        mapInstance.current.polygons.forEach((polygon) => polygon.setMap(null));
      }

      // Add new polygons
      mapInstance.current.polygons = polygons.map((polygonOptions) => {
        const polygon = new maps.Polygon(polygonOptions);
        polygon.setMap(mapInstance.current);
        return polygon;
      });
    }
  }, [maps, polygons]);

  return <div ref={mapRef} className="custom-google-maps__container" />;
}

export default CustomGmaps;
