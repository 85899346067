/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Link } from 'react-router-dom';
import SwiperNavButtonsTypeB from 'components/Buttons/SwiperNavButtonsTypeB';
import CardProduct from 'components/Cards/CardProduct';
import PropTypes from 'prop-types';

function SliderTypeD({ contents }) {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  function handleSlideChange(swiper) {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }

  return (
    <Swiper
      onSlideChange={(swiper) => {
        handleSlideChange(swiper);
      }}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1.3}
      className="w-100"
    >
      <div slot="container-start" className="w-100 mb-4">
        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
          <h3 className="detail-property-last-section--title">
            Properti <span className="app-color-text-10">Lainnya</span>
          </h3>
          <div className="detail-property-last-section--navigation">
            <Link to="/" className="btn-see-more--slider-type-b me-3">
              Lihat Selengkapnya
            </Link>
            <SwiperNavButtonsTypeB isBeginning={isBeginning} isEnd={isEnd} />
          </div>
        </div>
      </div>

      {contents.map((content) => {
        return (
          <SwiperSlide key={`content-${content.id}`} className="px-2 pt-2 pb-4">
            <CardProduct detail={content} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

SliderTypeD.propTypes = {
  contents: PropTypes.array.isRequired,
};

export default SliderTypeD;
