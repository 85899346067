/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from 'assets/logo--techpolitan-white.svg';
import arrowRight from 'assets/arrow--right.svg';
import './Header.modules.css';

export default function PageHeader() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`app-header-container${scrolled ? ' scrolled' : ''}`}>
      <div className="w-100 h-100 d-flex flex-row align-items-center justify-content-between">
        <Link className="app-header-logo-container" to="/">
          <img src={logo} alt="client-logo" />
        </Link>
        {location.pathname === '/property-cooperation' ? null : (
          <Link to="/property-cooperation" className="app-cta-property-cooperation__btn">
            <img src={arrowRight} className="arrow-right" alt="arrow-right" />

            <span className="text">Kerjasama Properti</span>
          </Link>
        )}
      </div>
    </header>
  );
}
