/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import propertyService from 'services/property.service';
import CardProduct from 'components/Cards/CardProduct';
import DropdownTypeA from 'components/Dropdowns/DropdownTypeA';
import arrowRight from 'assets/arrow--right.svg';
// import { ViewOnMapsButton } from 'components/Buttons/Button';
import NoProperty from 'assets/techpolitan-no-property.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './home.modules.css';

function Home() {
  // const navigate = useNavigate();
  const [payload, setPayload] = useState({
    page: 1,
    limit: 9,
    sort_by: 'id',
    is_asc: true,
    search: '',
    property_type_id: null,
    city_id: null,
    price_selection_id: null,
  });

  const [propertyList, setPropertyList] = useState([]);
  const [propertyListTotal, setPropertyListTotal] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [propertyTypesList, setPropertyTypesList] = useState([]);
  const [priceSelectionsList, setPriceSelectionsList] = useState([]);
  const [seeMore, setSeeMore] = useState(0);

  async function fetchProperty() {
    try {
      const payloadData = { ...payload };
      const response = await propertyService.propertyList(payloadData);
      setPropertyList(response?.data?.content);
      setPropertyListTotal(response?.data?.total_all_elements);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchSeeMoreProperty() {
    try {
      const payloadData = { ...payload };
      const response = await propertyService.propertyList(payloadData);
      if (response && response.data && response.data.content) {
        setPropertyList((prevValue) => [...response.data.content, ...prevValue]);
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  function fetchSeeMore() {
    setPayload({ ...payload, page: payload.page + 1 });
    setSeeMore(seeMore + 1);
  }

  async function fetchCities() {
    try {
      const response = await propertyService.cities();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });

      setCityList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchPropertyTypes() {
    try {
      const response = await propertyService.propertyTypes();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setPropertyTypesList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  async function fetchPriceSelections() {
    try {
      const response = await propertyService.priceSelections();
      const restructure = response?.data?.map((x) => {
        return { id: x?.id, label: x?.name };
      });
      setPriceSelectionsList(restructure);
    } catch (error) {
      throw new Error(error);
    }
  }

  function handleDropdown(param, value) {
    setPayload({ ...payload, [param]: value });
  }

  useEffect(() => {
    fetchProperty();
  }, [payload.price_selection_id, payload.property_type_id, payload.city_id]);

  useEffect(() => {
    // fetchWaterSources();
    fetchCities();
    fetchPropertyTypes();
    fetchPriceSelections();
  }, []);

  useEffect(() => {
    if (seeMore > 0) {
      fetchSeeMoreProperty();
    }
  }, [seeMore]);

  return (
    <main className="main-container">
      <div className="home-section-a--container">
        <div className="home-section-a-i-inner-container">
          <h1 className="home-section-a--text-a">Best value Indonesia</h1>
          <h1 className="home-section-a--text-a">properties</h1>
          <p className="home-section-a--text-b">
            Selamat datang di peluang investasi properti yang menarik! Kami memiliki penawaran
            eksklusif untuk Anda yang sedang mencari investasi properti yang menguntungkan. Dapatkan
            kesempatan emas untuk memiliki properti yang terletak di lokasi strategis dengan potensi
            pertumbuhan nilai yang tinggi.
          </p>
        </div>
        <div className="home-section-a-ii-inner-container">
          <div className="home-section-a--object">
            <div className="home-section-a--text-c">
              Investasi Properti Menjanjikan di{' '}
              <span className="app-color-text-10">Lokasi Strategis!</span>
            </div>
            <div className="home-section-a--text-d">
              <span className="app-color-text-10">20</span> people are viewing this page
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-b--container">
        <div className="home-section-b--filter-container">
          <div className="title">
            Investasi Properti Menjanjikan di{' '}
            <span className="app-color-text-10">Lokasi Strategis!</span>
          </div>
          <div className="home-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Mencari apa"
              paramName="property_type_id"
              key="property_type_id"
              dropdownId="property_type_id"
              options={propertyTypesList}
            />
          </div>
          <div className="home-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Lokasi"
              paramName="city_id"
              key="city_id"
              dropdownId="city_id"
              options={cityList}
            />
          </div>

          <div className="home-section-b--filter-container-child">
            <DropdownTypeA
              handleDropdown={handleDropdown}
              name="Harga"
              paramName="price_selection_id"
              key="price_selection_id"
              dropdownId="price_selection_id"
              options={priceSelectionsList}
            />
          </div>
        </div>
      </div>

      <div className="home-section-c--container">
        {/* <div className="d-flex flex-row justify-content-end w-100" style={{ marginBottom: '25px' }}>
          <ViewOnMapsButton
            onClick={() => {
              navigate('/view-on-maps');
            }}
          >
            View on maps
          </ViewOnMapsButton>
        </div> */}
        {propertyList && propertyList?.length > 0 ? (
          <div className="home-section-c--properties-container">
            {propertyList.map((property) => {
              return <CardProduct key={`content-${property?.id}`} detail={property} />;
            })}
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-start align-items-center w-100 my-5">
            <img src={NoProperty} alt="no-data" />
            <p
              style={{
                marginTop: '26.16px',
                lineHeight: '19.5px',
                letterSpacing: '-0.43px',
                color: '#979797',
                textAlign: 'center',
              }}
            >
              Properti tidak ditemukan. Belum ada hasil yang sesuai dengan pencarian Anda.
            </p>
          </div>
        )}

        <div className="d-flex flex-row justify-content-center">
          {propertyListTotal === propertyList?.length ? null : (
            <div className="d-flex flex-row justify-content-center">
              <button
                type="button"
                onClick={fetchSeeMore}
                className="properties-section-c--btn-see-more"
              >
                Lihat Lebih
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="home-section-d--container">
        <div className="home-section-d--inner-container">
          <h4 className="text">
            Jadi yang pertama dapatkan update terkini properti terbaik dan strategis
          </h4>
          <Link to="/property-cooperation" className="cta-button">
            <img src={arrowRight} className="arrow-right" alt="arrow-right" />
            <p className="text">Kerjasama Properti</p>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Home;
