/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { carImages } from 'utils/constanta';
import { shortenNumber } from 'utils/common';
import CustomGmaps from 'components/Customs/CustomGmaps';
import propertyService from 'services/property.service';
import ImageGallery from 'react-image-gallery';
import ImageLeftNav from 'components/Buttons/ImageLeftNav';
import ImageRightNav from 'components/Buttons/ImageRightNav';
import SliderTypeB from 'components/Sliders/SliderTypeB';
import SliderTypeC from 'components/Sliders/SliderTypeC';
import SliderTypeD from 'components/Sliders/SliderTypeD';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import 'react-image-gallery/styles/css/image-gallery.css';
import './properties.modules.css';

function PropertyDetails() {
  const { id } = useParams();
  const payload = {
    page: 1,
    limit: 9,
    sort_by: 'id',
    is_asc: true,
    search: '',
    property_type_id: null,
    city_id: null,
    sales_type_id: null,
    price_selection_id: null,
  };
  const apiKey = 'AIzaSyBG-iCQet_qGyQXeP2BxACG1-mrpb2GMsc';
  const [property, setProperty] = useState({} | null);
  const [polygonsOptions, setPolygonOptions] = useState([
    {
      paths: [
        { lat: -34.397, lng: 150.644 },
        { lat: -34.307, lng: 150.644 },
        { lat: -34.307, lng: 150.744 },
        { lat: -34.397, lng: 150.744 },
      ],
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    },
  ]);

  const [mapOptions, setMapOptions] = useState({
    center: { lat: -34.397, lng: 150.644 },
    zoom: 18,
  });

  const [propertyList, setPropertyList] = useState([]);
  // const [waterSourceList, setWaterSourceList] = useState([]);

  // async function fetchWaterSourceList() {
  //   try {
  //     const response = await propertyService.waterSources();
  //     setWaterSourceList(response?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async function fetchProperty() {
    try {
      const response = await propertyService.detailProperty(id);
      const restructure = {
        ...response?.data,
        images: response?.data?.images?.map((x) => {
          return { original: x.url, thumbnail: x.url };
        }),
      };
      const polygonsData = {
        paths: response?.data?.coords
          ? response?.data?.coords?.map((coord) => {
              return { lat: coord?.latitude, lng: coord?.longitude };
            })
          : polygonsOptions[0].paths,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
      };

      setMapOptions((prevValue) => {
        return {
          ...prevValue,
          center: {
            lat: response?.data?.coords ? response?.data?.coords[0].latitude : prevValue.center.lat,
            lng: response?.data?.coords
              ? response?.data?.coords[0].longitude
              : prevValue.center.lng,
          },
        };
      });

      setPolygonOptions([polygonsData]);
      setProperty(restructure);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchPropertyList() {
    try {
      const response = await propertyService.propertyList(payload);
      setPropertyList([...response?.data?.content]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchProperty();
    fetchPropertyList();
    // fetchWaterSourceList();
  }, []);

  return (
    <main className="main-container">
      <div className="detail-property-section-a--container">
        <div className="detail-property-section-a--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item bsPrefix="breadcrumb-item detail-property-breadcrumb-item" href="#">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              bsPrefix="breadcrumb-item detail-property-breadcrumb-item"
              href="https://getbootstrap.com/docs/4.0/components/breadcrumb/"
            >
              Properti
            </Breadcrumb.Item>
            <Breadcrumb.Item bsPrefix="breadcrumb-item detail-property-breadcrumb-item" active>
              {property?.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="detail-property-section-a--info-container">
          <div className="detail-property-section-a--info-content">
            <div className="detail-property-section-a--image-slider-container">
              <ImageGallery
                items={property?.images && property.images.length > 0 ? property.images : carImages}
                showPlayButton={false}
                renderLeftNav={(onClick, disabled) => (
                  <ImageLeftNav onClick={onClick} disabled={disabled} />
                )}
                renderRightNav={(onClick, disabled) => (
                  <ImageRightNav onClick={onClick} disabled={disabled} />
                )}
                showThumbnails={false}
              />
            </div>
          </div>
          <div className="detail-property-section-a--info-content">
            <div className="property-card-price-detail-title">{property?.name}</div>
            <div className="property-card-price-detail-location">
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00001 0.25C5.17732 0.252216 3.42993 0.977256 2.1411 2.26609C0.852264 3.55492 0.127223 5.30232 0.125007 7.125C0.122855 8.61454 0.60947 10.0636 1.51019 11.25C1.51019 11.25 1.69769 11.4966 1.72782 11.5323L7.00001 17.75L12.2744 11.5296C12.3021 11.4963 12.4898 11.2498 12.4898 11.2498L12.4908 11.2483C13.3909 10.0623 13.8772 8.61386 13.875 7.125C13.8728 5.30232 13.1478 3.55492 11.8589 2.26609C10.5701 0.977256 8.82269 0.252216 7.00001 0.25ZM7.00001 9.625C6.50555 9.625 6.0222 9.47838 5.61108 9.20367C5.19996 8.92897 4.87953 8.53852 4.69031 8.08171C4.50109 7.62489 4.45158 7.12223 4.54804 6.63727C4.64451 6.15232 4.88261 5.70686 5.23224 5.35723C5.58187 5.0076 6.02733 4.7695 6.51228 4.67304C6.99723 4.57657 7.4999 4.62608 7.95672 4.8153C8.41353 5.00452 8.80398 5.32495 9.07868 5.73607C9.35338 6.1472 9.50001 6.63055 9.50001 7.125C9.49926 7.78781 9.23563 8.42326 8.76695 8.89194C8.29827 9.36062 7.66282 9.62426 7.00001 9.625Z"
                  fill="#333333"
                />
              </svg>

              <span className="ms-2 app-color-text-1 card-property-type-i-body--location-text">
                {property?.location}, {property?.city_name}
              </span>
            </div>

            {property?.discounted_price ? (
              <div className="property-card-price-detail-normal-price">
                Rp {shortenNumber(property?.price)}
              </div>
            ) : null}

            <div className="property-card-price-detail-discount-price">
              Rp{' '}
              {property?.discounted_price
                ? shortenNumber(property?.discounted_price)
                : shortenNumber(property?.price)}
            </div>

            <a href={`https://wa.me/${property?.phone_number}`} className="btn-ask-property">
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2499 10.875H10.1862C2.81741 10.4513 1.77116 4.23375 1.62491 2.33625C1.61313 2.18872 1.63057 2.04031 1.67624 1.89953C1.72191 1.75875 1.79491 1.62836 1.89105 1.51584C1.9872 1.40333 2.1046 1.31088 2.23654 1.24381C2.36847 1.17675 2.51234 1.13637 2.65991 1.125H4.72616C4.87637 1.12485 5.02316 1.16982 5.14752 1.25406C5.27188 1.33831 5.36808 1.45795 5.42366 1.5975L5.99366 3C6.04854 3.13633 6.06216 3.28578 6.03283 3.42978C6.00349 3.57379 5.9325 3.706 5.82866 3.81L5.02991 4.61625C5.15468 5.32528 5.49423 5.97888 6.00262 6.48861C6.51101 6.99834 7.16371 7.33961 7.87241 7.46625L8.68616 6.66C8.79172 6.55731 8.92518 6.48797 9.06989 6.46063C9.2146 6.43329 9.36416 6.44915 9.49991 6.50625L10.9137 7.0725C11.0511 7.12983 11.1684 7.22678 11.2505 7.351C11.3327 7.47522 11.376 7.62108 11.3749 7.77V9.75C11.3749 10.0484 11.2564 10.3345 11.0454 10.5455C10.8344 10.7565 10.5483 10.875 10.2499 10.875Z"
                  fill="white"
                />
              </svg>
              <span className="ms-1">Tanya Properti</span>
            </a>
          </div>
          <div className="detail-property-section-a--info-content">
            <p className="property-detail-description">
              Miliki kavling tanah premium di lokasi strategis. harga yang sangat kompetitif, izin
              dan dokumen lengkap. Anda dapat dengan mudah memulai proses pembangunan tanpa harus
              khawatir tentang masalah perizinan.
              {property?.description}
            </p>
            <div className="property-detail-specification-title">Specifications and Facilities</div>
            <div className="property-detail-specification-content mb-5">
              <div className="property-detail-specification-content-i">
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Garasi</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.garage ? property?.garage + ' ' + 'mobil' : '-'}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Sumber Air</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.water_source_name ? property?.water_source_name : '-'}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Daya Listrik</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.electrical_power ? property?.electrical_power : '0'}W
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Kamar Tidur</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.bedroom_total ? property?.bedroom_total : '-'}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Kamar Mandi</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.bathroom_total ? property?.bathroom_total : '-'}
                  </div>
                </div>
              </div>
              <div className="property-detail-specification-content-i">
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Furnish</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.furnished ? 'Furnished' : 'Non Furnished'}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Legalitas</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.legality ? property?.legality : '-'}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Luas Tanah</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.lot_area} m<sup>2</sup>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Luas Bangunan</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.floor_area ? property?.floor_area : '-'} m<sup>2</sup>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mb-2">
                  <div className="montserrat-medium txt-12px">Lantai</div>
                  <div className="montserrat-bold txt-12px">
                    {property?.floor_levels ? property?.floor_levels : '-'}
                  </div>
                </div>
              </div>
            </div>
            <CustomGmaps apiKey={apiKey} options={mapOptions} polygons={polygonsOptions} />
          </div>
        </div>

        <div className="border-line-type-a" />
      </div>
      <div className="detail-property-section-b--container">
        <div className="property-last-section">
          <SliderTypeB contents={propertyList || []} />
        </div>
        <div className="property-last-section">
          <SliderTypeC contents={propertyList || []} />
        </div>
        <div className="property-last-section">
          <SliderTypeD contents={propertyList || []} />
        </div>
      </div>
      {/* <div className="app-body-container">
        <div className="px-app-default">
          <div className="property-detail-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item bsPrefix="breadcrumb-item app-breadcrumb-item" href="#">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                bsPrefix="breadcrumb-item app-breadcrumb-item"
                href="https://getbootstrap.com/docs/4.0/components/breadcrumb/"
              >
                Properti
              </Breadcrumb.Item>
              <Breadcrumb.Item bsPrefix="breadcrumb-item app-breadcrumb-item" active>
                Rose Garden
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="card-detail-section">
            <div className="d-flex flex-row align-items-start justify-content-between">
              <div className="property-image-slider-container">
                <ImageGallery
                  items={carImages}
                  showPlayButton={false}
                  renderLeftNav={(onClick, disabled) => (
                    <ImageLeftNav onClick={onClick} disabled={disabled} />
                  )}
                  renderRightNav={(onClick, disabled) => (
                    <ImageRightNav onClick={onClick} disabled={disabled} />
                  )}
                  showThumbnails={false}
                />
              </div>
              <div className="property-card-price-container">
                <div className="property-card-price-detail-title">Rose Garden</div>
                <div className="property-card-price-detail-location">
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.00001 0.25C5.17732 0.252216 3.42993 0.977256 2.1411 2.26609C0.852264 3.55492 0.127223 5.30232 0.125007 7.125C0.122855 8.61454 0.60947 10.0636 1.51019 11.25C1.51019 11.25 1.69769 11.4966 1.72782 11.5323L7.00001 17.75L12.2744 11.5296C12.3021 11.4963 12.4898 11.2498 12.4898 11.2498L12.4908 11.2483C13.3909 10.0623 13.8772 8.61386 13.875 7.125C13.8728 5.30232 13.1478 3.55492 11.8589 2.26609C10.5701 0.977256 8.82269 0.252216 7.00001 0.25ZM7.00001 9.625C6.50555 9.625 6.0222 9.47838 5.61108 9.20367C5.19996 8.92897 4.87953 8.53852 4.69031 8.08171C4.50109 7.62489 4.45158 7.12223 4.54804 6.63727C4.64451 6.15232 4.88261 5.70686 5.23224 5.35723C5.58187 5.0076 6.02733 4.7695 6.51228 4.67304C6.99723 4.57657 7.4999 4.62608 7.95672 4.8153C8.41353 5.00452 8.80398 5.32495 9.07868 5.73607C9.35338 6.1472 9.50001 6.63055 9.50001 7.125C9.49926 7.78781 9.23563 8.42326 8.76695 8.89194C8.29827 9.36062 7.66282 9.62426 7.00001 9.625Z"
                      fill="#333333"
                    />
                  </svg>

                  <span className="ms-2 app-color-text-1 card-property-type-i-body--location-text">
                    Soekarno Hatta, Bandung
                  </span>
                </div>
                <div className="property-card-price-detail-normal-price">Rp 8,50 Miliar</div>
                <div className="property-card-price-detail-discount-price">Rp 4.92 Miliar</div>
                <a className="btn-ask-property">
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2499 10.875H10.1862C2.81741 10.4513 1.77116 4.23375 1.62491 2.33625C1.61313 2.18872 1.63057 2.04031 1.67624 1.89953C1.72191 1.75875 1.79491 1.62836 1.89105 1.51584C1.9872 1.40333 2.1046 1.31088 2.23654 1.24381C2.36847 1.17675 2.51234 1.13637 2.65991 1.125H4.72616C4.87637 1.12485 5.02316 1.16982 5.14752 1.25406C5.27188 1.33831 5.36808 1.45795 5.42366 1.5975L5.99366 3C6.04854 3.13633 6.06216 3.28578 6.03283 3.42978C6.00349 3.57379 5.9325 3.706 5.82866 3.81L5.02991 4.61625C5.15468 5.32528 5.49423 5.97888 6.00262 6.48861C6.51101 6.99834 7.16371 7.33961 7.87241 7.46625L8.68616 6.66C8.79172 6.55731 8.92518 6.48797 9.06989 6.46063C9.2146 6.43329 9.36416 6.44915 9.49991 6.50625L10.9137 7.0725C11.0511 7.12983 11.1684 7.22678 11.2505 7.351C11.3327 7.47522 11.376 7.62108 11.3749 7.77V9.75C11.3749 10.0484 11.2564 10.3345 11.0454 10.5455C10.8344 10.7565 10.5483 10.875 10.2499 10.875Z"
                      fill="white"
                    />
                  </svg>
                  <span className="ms-1">Tanya Properti</span>
                </a>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-start">
              <div className="property-detail-container">
                <p className="property-detail-description">
                  Miliki kavling tanah premium di lokasi strategis. harga yang sangat kompetitif,
                  izin dan dokumen lengkap. Anda dapat dengan mudah memulai proses pembangunan tanpa
                  harus khawatir tentang masalah perizinan.
                </p>
                <div className="property-detail-specification-title">
                  Specifications and Facilities
                </div>
                <div className="property-detail-specification-content mb-5">
                  <div className="property-detail-specification-content-i">
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Garasi</div>
                      <div className="montserrat-bold txt-12px">2 Mobil</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Sumber Air</div>
                      <div className="montserrat-bold txt-12px">Air Pam</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Daya Listrik</div>
                      <div className="montserrat-bold txt-12px">4400 watt</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Kamar Tidur</div>
                      <div className="montserrat-bold txt-12px">4</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Kamar Mandi</div>
                      <div className="montserrat-bold txt-12px">3</div>
                    </div>
                  </div>
                  <div className="property-detail-specification-content-i">
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Furnish</div>
                      <div className="montserrat-bold txt-12px">Non Furnished</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Legalitas</div>
                      <div className="montserrat-bold txt-12px">SHM</div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Luas Tanah</div>
                      <div className="montserrat-bold txt-12px">
                        466 m<sup>2</sup>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Luas Bangunan</div>
                      <div className="montserrat-bold txt-12px">
                        350 m<sup>2</sup>
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-2">
                      <div className="montserrat-medium txt-12px">Lantai</div>
                      <div className="montserrat-bold txt-12px">2</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-app-default">
          <div className="border-line-type-a" />
        </div>

        <div className="property-last-section px-app-default">
          <SliderTypeB contents={newProperties} />
        </div>
      </div> */}
    </main>
  );
}

export default PropertyDetails;
